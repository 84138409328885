import React from 'react';
import Card from 'react-bootstrap/Card';
import data from '../../data'; 
import { Link } from 'react-router-dom';
import { useScroll } from '../../ScrollContext';


const TraitsLeft = () => {
  const { title, content, linkText1, linkText2, linkText3 } = data.Traits[0].traitsLeftData;
  const { beforeAfterRef1, beforeAfterRef2, beforeAfterRef3 } = useScroll();

  const scrollToRefWithOffset = (ref) => {
    if (!ref.current) return;
  
    // Assurez-vous d'abord que l'élément est visible
    ref.current.scrollIntoView({ behavior: 'smooth' });
    
    const windowWidth = window.innerWidth;

  // Définissez le délai en fonction de la plateforme
    const delay = windowWidth <= 768 ? 0 : 1000; // Par exemple, utilisez 768 comme seuil pour mobile

   
    // Permettez à la navigation de se terminer en ajoutant un léger délai
    setTimeout(() => {
    const elementPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
    const viewportHeight = window.innerHeight;
    let offset = elementPosition - (viewportHeight / 2) + (ref.current.offsetHeight / 2);

    if (windowWidth <= 768) {
      offset -= 0.045 * window.innerHeight;
    }
      // Ajustez la position de défilement
      window.scrollBy({
        top: offset,
        behavior: 'smooth'
      });
    }, delay); // 100ms devrait suffire, mais vous pouvez ajuster ce délai au besoin
  };
  
  
  return (
    <div className='left-flex-cab-pad0'>
      <Card className='left-card-trait'>
        <Card.Body className='flex-dr'>
          <Card.Title className='cab-text'>{title}</Card.Title>
          <Card.Text className='current'>
            {content.map((paragraph, index) => (
              <span key={index} className='current-black'>{paragraph}</span>
            ))}
          </Card.Text>
          <div className='flex-link-traits'>
            <Link 
              className='link-classic' 
              onClick={(e) => {
                e.preventDefault();
                scrollToRefWithOffset(beforeAfterRef1);
              }}
            >
              {linkText1}
            </Link>
            <Link 
              className='link-classic' 
              onClick={(e) => {
                e.preventDefault();
                scrollToRefWithOffset(beforeAfterRef2);
              }}
            >
              {linkText2}
            </Link>
            <Link 
              className='link-classic' 
              onClick={(e) => {
                e.preventDefault();
                scrollToRefWithOffset(beforeAfterRef3);
              }}
            >
              {linkText3}
            </Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TraitsLeft;
