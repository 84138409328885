import doc from "./img/dentiste-.png"
import inter1 from "./img/inter1.png"
import inter2 from "./img/inter2.jpg"
import b1 from "./img/bag1.jpg"
import b2 from "./img/bag2.jpg"
import inv1 from "./img/inv1.jpg"
import inv2 from "./img/inv2.jpg"
import cab1 from "./img/cab1.png"
import cab2 from "./img/cab2.png"
import cab3 from "./img/cab3.jpg"
import cline from "./img/cline.png"

const data = {

    Home: [
        {
            homeLeftData: {
                title: (
                    <div> 
                       Cabinet d’orthondontie 
                       <br/>
                       du Dr Hanras à Versailles
                    </div>
                    ),
                address: "16 rue Benjamin Franklin 78000 Versailles",
                buttons: [
                    {
                        text: "Appeler",
                        action: "call", // Vous pouvez définir une action spécifique pour chaque bouton
                    },
                    {
                        text: "Prendre Rendez-vous",
                        action: "book", // Vous pouvez définir une action spécifique pour chaque bouton
                    },
                ],
                svgIcon: `<svg width="22" height="9" viewBox="0 0 22 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector 11" d="M21.3536 4.85355C21.5488 4.65829 21.5488 4.34171 21.3536 4.14645L18.1716 0.964465C17.9763 0.769203 17.6597 0.769203 17.4645 0.964465C17.2692 1.15973 17.2692 1.47631 17.4645 1.67157L20.2929 4.5L17.4645 7.32843C17.2692 7.52369 17.2692 7.84027 17.4645 8.03553C17.6597 8.2308 17.9763 8.2308 18.1716 8.03553L21.3536 4.85355ZM0.5 5L21 5L21 4L0.5 4L0.5 5Z" fill="black" />
            </svg>`,
            },
        },

        {
            homeRightFirstData: {
                photo: doc,
                content: (
                    <div>
                        Installée en exercice libéral à Versailles depuis 2007, 
                        <br/>
                        Le Dr Hanras pratique l’orthodontie exclusive pour les enfants, pour les adolescents et pour les adultes. 
                        <br/>
                    </div>
                ),

                linkText: "En savoir plus",
                linkHref: "/lecabinet", // Vous pouvez remplacer "#" par l'URL de destination réelle
            },


            homeRightSecondData: {
                cards: [
                    {
                        id: "1",
                        text: "Dr Marie-Christine Hanras Docteur en chirurgie dentaire",
                        sub: "01/06/1991"
                    },
                    {
                        id: "2",
                        text: "Inscrite à l’ordre N 78-5281",
                        sub: "01/09/2007"
                    },
                    {
                        id: "3",
                        text: "Diplomée de la faculté de chirurgie dentaire Paris V",
                        sub: "01/06/1990"
                    },
                    {
                        id: "4",
                        text: "Ancienne Attachée des Hopitaux de Paris",
                        sub: "1991-1992"
                    },
                    {
                        id: "5",
                        text: "Membre de la SFODF",
                        sub: "01/01/2002"
                    },
                    {
                        id: "6",
                        text: "Certifiée INVISALIGN (2013)",
                        sub: "2010"
                    },
                ],
            },

            homeRightThirdData: {
                titlePhilo: "Philosophie du cabinet",
                contentPhilo: [
                    [
                        { text: "Orthosmile est un cabinet ", color: "black" },
                        { text: "d’orthodontie privé. ", color: "black" },
                        { text: "Le docteur Hanras attache le plus grand soin à vous proposer des traitements ", color: "black" },
                        { text: "de qualité, personnalisés. ", color: "black" },
                        { text: "Sa philosophie est axée sur la douceur,", color: "black" },
                        { text: " l’écoute et la compréhension.", color: "black" },

                    ],

                ],
                linkTextPhilo: "Voir le cabinet",
                linkHrefPhilo: "/lecabinet", // Vous pouvez remplacer "#" par l'URL de destination réelle

            },
        },

    ],

    LeCab: [
        {
            CabinetLeftData: {
                title: "Le cabinet",
                content: [
                    "Orthosmile est un cabinet d’orthodontie privé, le docteur Hanras attache le plus grand soin à vous proposer des traitements de qualité, personnalisés adaptés à vos besoins. Sa philosophie est axée sur la douceur, l’écoute, la compréhension et la bonne humeur. On y retrouve le bonheur de sourire !",
                    " La prise en charge de la douleur ayant considérablement évolué, le docteur Hanras fera en sorte que votre traitement soit le moins inconfortable possbile."
                ],
            },
        },

        {
            cabRightFirstData: {
                photo1: cab1,
                photo2: cab2,
                photo3: cab3,
            },

            cabRightSecondData: {
                cards: [
                    {
                        id: "1",
                        svgIcon:
                            <svg width="2.2vw" viewBox="0 0 40 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.3477 11.1865H1.255V16.0251H15.0961" stroke="black" />
                                <path d="M4.0199 8.89453C4.0199 8.61839 3.79604 8.39453 3.5199 8.39453C3.24376 8.39453 3.0199 8.61839 3.0199 8.89453H4.0199ZM3.0199 8.89453V11.1865H4.0199V8.89453H3.0199Z" fill="black" />
                                <path d="M7.29138 8.89453C7.29138 8.61839 7.06752 8.39453 6.79138 8.39453C6.51524 8.39453 6.29138 8.61839 6.29138 8.89453H7.29138ZM6.29138 8.89453V11.1865H7.29138V8.89453H6.29138Z" fill="black" />
                                <path d="M10.3113 8.89453C10.3113 8.61839 10.0874 8.39453 9.81128 8.39453C9.53514 8.39453 9.31128 8.61839 9.31128 8.89453H10.3113ZM9.31128 8.89453V11.1865H10.3113V8.89453H9.31128Z" fill="black" />
                                <path d="M13.3312 8.89453C13.3312 8.61839 13.1073 8.39453 12.8312 8.39453C12.555 8.39453 12.3312 8.61839 12.3312 8.89453H13.3312ZM12.3312 8.89453V11.1865H13.3312V8.89453H12.3312Z" fill="black" />
                                <path d="M15.3477 18.0624V3.0373C15.3477 2.3582 15.8006 1 17.6126 1C18.8708 1 20.1291 2.52797 20.8841 3.29196" stroke="black" />
                                <path d="M27.4272 2.78261L20.6325 9.65848C19.7936 8.55495 18.7199 5.73669 21.1358 3.29193C23.5517 0.847175 26.3367 1.93373 27.4272 2.78261Z" stroke="black" />
                                <path d="M21.6391 39.9629V32.0684H23.4007C24.206 32.0684 24.7428 31.5591 24.9106 31.3044L38.5 15.77L35.4801 12.9688L22.6457 27.9938H13.3344L4.52649 32.0684L6.03642 35.379L11.3212 33.087V39.9629H8.04967V43.0189H24.9106V39.9629H21.6391Z" stroke="black" />
                                <path d="M32.9669 10.677C32.9669 12.0943 31.8331 13.233 30.447 13.233C29.0609 13.233 27.9271 12.0943 27.9271 10.677C27.9271 9.25981 29.0609 8.12109 30.447 8.12109C31.8331 8.12109 32.9669 9.25981 32.9669 10.677Z" fill="black" stroke="black" />
                                <path d="M12.0762 25.1927L4.50208 28.8233C3.39986 29.3517 2.07771 28.8952 1.53625 27.7993C0.971986 26.6573 1.47635 25.2754 2.64358 24.7654L11.5728 20.8635H17.6126C19.2232 20.8635 20.2969 19.8448 20.6324 19.3355L25.2353 14.6777C26.0261 13.8775 27.3183 13.8775 28.1091 14.6777C28.8578 15.4354 28.8902 16.6439 28.1782 17.4362C26.4867 19.3188 24.1468 21.8911 22.8973 23.1555C21.2868 24.7853 19.2064 25.1927 18.3675 25.1927H12.0762Z" stroke="black" />
                            </svg>,
                        text: "Fauteuil",
                        sub: ""
                    },
                    {
                        id: "2",
                        svgIcon:
                            <svg width="2.2vw" viewBox="0 0 93 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="42" y="52" width="46" height="51" rx="4" stroke="black" stroke-width="2" stroke-linejoin="round" />
                                <rect x="23" y="32" width="36" height="13" rx="5" stroke="black" stroke-width="2" stroke-linejoin="round" />
                                <rect x="36.4109" y="2.17195" width="62.7968" height="10.3244" rx="5" transform="rotate(28.7182 36.4109 2.17195)" stroke="black" stroke-width="2" stroke-linejoin="round" />
                                <path d="M42 70.5H6.5C4.83333 70.1667 1.5 68.6 1.5 65C1.5 61.4 4.83333 59.8333 6.5 59.5H42" stroke="black" stroke-width="2" />
                                <path d="M14.5 71V75" stroke="black" stroke-width="2" />
                                <path d="M37 15L37 32" stroke="black" stroke-width="2" />
                                <path d="M45 19L45 32" stroke="black" stroke-width="2" />
                                <path d="M74 35L74 50" stroke="black" stroke-width="2" />
                                <path d="M81 39L81 50" stroke="black" stroke-width="2" />
                                <path d="M29 71V75" stroke="black" stroke-width="2" />
                                <rect x="10" y="76" width="24" height="27" rx="2" stroke="black" stroke-width="2" stroke-linejoin="round" />
                                <circle cx="22.5" cy="84.5" r="3.5" fill="black" />
                                <circle cx="22.5" cy="94.5" r="3.5" fill="black" />
                                <path d="M62 58.5H67.5" stroke="black" stroke-width="2" />
                                <path d="M23 35H16C15.4477 35 15 35.4477 15 36V40.5C15 41.0523 15.4477 41.5 16 41.5H23" stroke="black" stroke-width="2" />
                                <path d="M72 58.5H79.5C80.0523 58.5 80.5 58.9477 80.5 59.5V68.5" stroke="black" stroke-width="2" />
                                <path d="M47 80.5H56" stroke="black" stroke-width="2" />
                                <path d="M75 80.5H82" stroke="black" stroke-width="2" />
                                <path d="M58.8204 68.015C52.0003 68.6548 52.0345 77.5261 56 80.5002C52 86.5001 55 93.5001 57.5 95.0002C57.5 89.5002 57 83 65.5 83C74 83 73.5 90 73 95.0002C77.8 91.4004 76.3333 83.8336 75 80.5002C81 74.5002 75 66.4999 70.5 68.0002C66.8501 69.2171 61.5863 68.693 59.1687 68.0468C59.0558 68.0166 58.9367 68.0041 58.8204 68.015Z" stroke="black" stroke-width="2" />
                                <path d="M61.5 72.5C62.8333 73 66.1 73.7 68.5 72.5" stroke="black" stroke-width="2" />
                            </svg>


                        ,
                        text: "Téléradiographie",
                        sub: ""
                    },
                    {
                        id: "3",
                        svgIcon:
                        <svg width="2.2vw" viewBox="0 0 93 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="42" y="52" width="46" height="51" rx="4" stroke="black" stroke-width="2" stroke-linejoin="round"/>
                        <rect x="23" y="32" width="36" height="13" rx="5" stroke="black" stroke-width="2" stroke-linejoin="round"/>
                        <rect x="36.411" y="2.17195" width="62.7968" height="10.3244" rx="5" transform="rotate(28.7182 36.411 2.17195)" stroke="black" stroke-width="2" stroke-linejoin="round"/>
                        <path d="M42 70.5H6.49997C4.8333 70.1667 1.49997 68.6 1.49997 65C1.49997 61.4 4.8333 59.8333 6.49997 59.5H42" stroke="black" stroke-width="2"/>
                        <path d="M14.5 71V75" stroke="black" stroke-width="2"/>
                        <path d="M37 15L37 32" stroke="black" stroke-width="2"/>
                        <path d="M45 19L45 32" stroke="black" stroke-width="2"/>
                        <path d="M74 35L74 50" stroke="black" stroke-width="2"/>
                        <path d="M81 39L81 50" stroke="black" stroke-width="2"/>
                        <path d="M29 71V75" stroke="black" stroke-width="2"/>
                        <rect x="9.99997" y="76" width="24" height="27" rx="2" stroke="black" stroke-width="2" stroke-linejoin="round"/>
                        <circle cx="22.5" cy="84.5" r="3.5" fill="black"/>
                        <circle cx="22.5" cy="94.5" r="3.5" fill="black"/>
                        <path d="M62 58.5H67.5" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M23 35H16C15.4477 35 15 35.4477 15 36V40.5C15 41.0523 15.4477 41.5 16 41.5H23" stroke="black" stroke-width="2"/>
                        <path d="M72 58.5H79.5C80.0523 58.5 80.5 58.9477 80.5 59.5V68.5" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M47 79.5C46.4477 79.5 46 79.9477 46 80.5C46 81.0523 46.4477 81.5 47 81.5V79.5ZM47 81.5H56V79.5H47V81.5Z" fill="black"/>
                        <path d="M82 81.5C82.5523 81.5 83 81.0523 83 80.5C83 79.9477 82.5523 79.5 82 79.5V81.5ZM75 81.5H82V79.5H75V81.5Z" fill="black"/>
                        <path d="M61.5 72.5C62.8333 73 66.1 73.7 68.5 72.5" stroke="black" stroke-width="2"/>
                        <path d="M56.6606 65.0734C55.1852 65.0734 50.5376 64.073 52.4579 76.8668C53.1025 81.1613 54.559 88.8729 55.6606 94.0734C56.6606 98.7942 61.0376 97.5726 61.5376 94.5728L63.0376 88.0009C63.5376 85.5009 66.0376 85.5009 66.5376 88.0009C66.8514 89.5698 67 90.5009 68.0376 94.0734C69 98.501 72.5376 98.0009 73.5376 94.0734L76.7404 76.3047C77.6743 66.7581 75.7386 64.5431 72.5376 65.0734L56.6606 65.0734Z" fill="white" stroke="black" stroke-width="2"/>
                        </svg>
                        
                        ,
                        text: "Radio Panoramique",
                        sub: ""
                    },
                    {
                        id: "4",
                        svgIcon:
                        <svg width="2.2vw" viewBox="0 0 111 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_311_478)">
                        <path d="M68.0587 19.1963H81.1734" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M83.2718 19.1963H88.5177" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <rect x="65.9111" y="16" width="43.6393" height="30" rx="4" stroke="black" stroke-width="2" stroke-linejoin="round"/>
                        <path d="M64.0216 31.3303C65.5039 41.652 69.0972 43.1562 72.2694 43.1562C76.3933 43.1562 79.8828 37.4337 80.5172 30.9485C81.1517 24.4633 79.8367 22.9586 77.6622 23.3188L66.8766 23.3188C65.8744 23.3188 62.67 21.9183 64.0216 31.3303Z" stroke="black" stroke-width="2"/>
                        <path d="M81.6706 30.6159C82.9484 40.0269 86.0461 41.3984 88.7808 41.3984C92.3358 41.3984 95.344 36.1808 95.8909 30.2678C96.4379 24.3548 95.3042 22.9829 93.4297 23.3113L84.1318 23.3113C83.2678 23.3113 80.5054 22.0345 81.6706 30.6159Z" stroke="black" stroke-width="2"/>
                        <path d="M96.977 29.4273C98.0504 37.3204 100.652 38.4707 102.95 38.4707C105.936 38.4707 108.463 34.0946 108.922 29.1353C109.382 24.1761 108.429 23.0254 106.855 23.3009L99.0445 23.3009C98.3187 23.3009 95.9983 22.23 96.977 29.4273Z" stroke="black" stroke-width="2"/>
                        </g>
                        <path d="M33.3972 65.8061C35.195 78.3249 39.5532 80.1494 43.4007 80.1494C48.4024 80.1494 52.6347 73.2087 53.4042 65.343C54.1737 57.4773 52.5788 55.6523 49.9414 56.0892L36.86 56.0892C35.6443 56.0892 31.7579 54.3907 33.3972 65.8061Z" stroke="black" stroke-width="2"/>
                        <path d="M54.9954 65.8061C56.7931 78.3249 61.1514 80.1494 64.9988 80.1494C70.0006 80.1494 74.2328 73.2087 75.0023 65.343C75.7718 57.4773 74.1769 55.6523 71.5396 56.0892L58.4581 56.0892C57.2425 56.0892 53.3561 54.3907 54.9954 65.8061Z" stroke="black" stroke-width="2"/>
                        <path d="M76.5387 64.9408C78.0885 76.355 81.8456 78.0186 85.1624 78.0186C89.4742 78.0186 93.1227 71.6902 93.7861 64.5186C94.4494 57.3469 93.0745 55.683 90.801 56.0813L79.5238 56.0813C78.4759 56.0813 75.1255 54.5326 76.5387 64.9408Z" stroke="black" stroke-width="2"/>
                        <path d="M95.2409 63.4994C96.5428 73.0726 99.6987 74.4678 102.485 74.4678C106.107 74.4678 109.171 69.1601 109.729 63.1452C110.286 57.1303 109.131 55.7347 107.221 56.0688L97.7484 56.0688C96.8681 56.0688 94.0538 54.7699 95.2409 63.4994Z" stroke="black" stroke-width="2"/>
                        <path d="M24.2065 9.92542C20.3983 8.0213 16.6694 10.7188 15.2809 12.3056L12.3057 15.2813C9.92558 17.6619 12.9008 22.2236 14.6859 24.2069C21.2313 29.1656 34.9172 39.3209 37.2974 40.273C39.6775 41.225 45.4296 40.6696 48.0081 40.273C54.9502 44.2399 69.9055 52.6498 74.1898 54.5539C78.474 56.458 79.1484 58.1241 78.9501 58.7192C79.9418 59.7109 83.1153 62.2894 87.8756 64.6695C90.7318 62.7654 87.479 55.5456 85.4955 52.1737C77.76 47.2151 61.4559 36.7027 58.1237 34.3226C53.9584 31.3474 49.7932 27.1821 47.413 23.6119C45.0329 20.0416 38.4874 17.0664 37.2974 16.4714C36.1073 15.8764 28.9668 12.3056 24.2065 9.92542Z" fill="white" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M46.818 23.6113C46.818 25.5948 43.8428 30.7518 31.942 35.5121" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M53.9584 31.3467C51.7766 32.5368 47.532 35.988 48.0081 40.2723" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M1 6.35547L8.7355 11.7108" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <path d="M1 1L10.5206 8.14046" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        <defs>
                        <clipPath id="clip0_311_478">
                        <rect width="46" height="32" fill="white" transform="translate(65 15)"/>
                        </clipPath>
                        </defs>
                        </svg>,
                        text: "Empreintes optiques",
                        sub: ""
                    },
                ]
            },


            cabRightHalfThirdData: {
                titlePrise: "Prise en charge",
                contentPrise: [
                    {
                        age: "Avant l'âge de 16ans :",
                        details: [
                            "Les traitements orthodontiques sont pris en charge par l'Assurance Maladie (sous réserve d'obtenir l'accord préalable de votre caisse de sécurité sociale), s'ils sont commencés avant le 16ème anniversaire.",
                            "L'accord préalable est établi pour 1 semestre. Il est à renouveler tous les 6 mois. La somme remboursée pour ce semestre est toujours la même quelques soient le praticien ou la technique utilisée : 193,50€",
                            "La différence entre les honoraires et le remboursement sécurité sociale peut être pris en charge partiellement ou en totalité par votre complémentaire santé."
                        ]
                    },
                    {
                        age: "Après l'âge de 16ans :",
                        details: [
                            "Il n'y a aucune particpation de l'Assurance Maladie.",
                            "Certaines assurances complémentaires de santé selon votre contrat, prennent en charge tout ou une partie des honoraires.",
                            "A titre exeptionnel, une prise en charge d'un unique semestre par l'Assurance Maladie est possible si une préparation orthodontique est nécessaire dans le cadre d'une chirurgie Maxillo-Faciale.",
                        ]
                    }
                ]
            }
            ,

            cabRightThirdData: {
                titleHono: "Honoraires",
                subtitleHono: "Le tarif des traitements orthodontiques est libre.",
                sub: "Il est forfaitaire et indépendant de la fréquence des rendez-vous. Dans tous les cas, un devis vous sera remis avant de débuter le traitement. Ses modalités administratives vous seront également communiquées et expliquées. ",
                contentHono: [
                    {
                        details: [
                            {
                                description: "Le montant des honoraires correspond : ",
                                list: [
                                    "à la pathologie",
                                    "à la technique utilisée",
                                    "à la durée du traitement",
                                    "à l'âge du patient",
                                ],
                            }

                        ]
                    },



                ],
            },

            cabRightFourthdData: {
                titleBil: "Le bilan orthodontique",
                contentBil: [
                    [

                        { title: "1er Rendez-vous ", color: "black" },
                        { text: "Après avoir rempli un questionnaire médical, vous rencontrerez le docteur pour une première consultation. Ce rendez-vous est d'une grande importance. Le Dr Hanras, après un examen exo-buccal et endo-buccal, discutera avec vous de l'opportunité d'un traitement orthodontique.", color: "black" },
                        { title: "2e Rendez-vous ", color: "black" },
                        { text: "Si un traitement est nécessaire, le docteur aura besoin d'affiner son diagnostic avec des photographies, des empreintes optiques, des radiographies etc... L'ensemble de ce dossier est constitué au cabinet lors de cette deuxième consultation.", color: "black" },
                        { title: "3e Rendez-vous ", color: "black" },
                        { text: "Le Dr Hanras vous présentera et vous expliquera son diagnostic et son plan de traitement. Céline, l'assistante dentaire, vous présentera et vous expliquera le devis et ses modalités de remboursement.", color: "black" },
                    ],

                ],
            },

            cabRightFifthdData: {
                titleUrg: "Les urgences",
                contentUrg: [
                    [
                        { text: "En cas de gêne, d'inconfort, de douleur au tout autre problème, appelez le cabinet et nous évaluerons ensemble l'urgence.", color: "black" },
                        { text: "En notre absence vous pouvez vous rapprocher de votre chirurgien dentiste.", color: "black" },
                        { text: "Les dimanches et jours fériés, vous obtiendrez les coordonnées du dentiste de garde en téléphonant au commissariat ou à la gendarmerie de votre ville. Vous pouvez aussi contacter l'hôpital le plus proche. ", color: "black" },
                        { text: "Pour une urgence vitale, contacter le 15.", color: "black" },
                    ],

                ],
                linkTextUrg: "Nous contacter",
                linkHrefUrg: "/contact", // Vous pouvez remplacer "#" par l'URL de destination réelle

            },

        },
    ],


    Traits: [
        {
            traitsLeftData: {
                title: "Les traitements",
                content: [
                    "Orthosmile est un cabinet d’orthodontie privé, le docteur Hanras attache le plus grand soin à vous proposer des traitements de qualité, personnalisés.",
                    "Sa philosophie est axée sur la douceur, l’écoute et la compréhension.",
                ],
                linkText1: "Pour enfants",
                linkHref1: "#", // Vous pouvez remplacer "#" par l'URL de destination réelle
                linkText2: "Pour adoslescents",
                linkHref2: "#", // Vous pouvez remplacer "#" par l'URL de destination réelle
                linkText3: "Pour adultes",
                linkHref3: "#", // Vous pouvez remplacer "#" par l'URL de destination réelle
            },
        },

        {
            traitsRightFirstData: {
                photobefore1: inter1,
                photoafter1: inter2,
                title1: "Chez l'enfant",
                descrip1: "Le traitement a pour but de : ",
                content1: [
                    {
                        title: [
                            "On parle de traitement précoce d'interception.",
                            "L'enfant est en denture mixte (il y a, à la fois, en bouche, des dents de lait et des dents définitives.)",
                        ],
                        content: [
                            "Prévenir et corriger les décalages des mâchoires",
                            'Préparer la place pour accueillir toutes les dents permanentes',
                            "Corriger / améliorer les fonctions de respiration, mastication, phonation, déglutition",
                            "Faire cesser les para-fonctions telles que succion du pouce, doigt, tétine, doudou etc",
                        ],
                    },

                ],
            },

            traitsRightSecondData: {
                photobefore2: b1,
                photoafter2: b2,
                title2: "Chez l'adolescent",
                descrip2: "L'objectif du traitement est :",
                content2: [
                    {
                        title: ["L'adolescent a toutes ses dents définitives en bouche.",
                            " Le plus souvent un appareil multibague est préconisé, il peut être métallique ou céramique."],

                        content: [
                            "D'emmener les dents permanentes dans la position souhaitée",
                            'De réduire les décalages squelettiques non traités ou améliorés par une orthodontie précoce',
                            "Corriger les fonctions de la sphère oro-faciale (ventilation, mastication, phonation, déglutition) non prises en charge par une orthodontie précoce",
                            "Faire cesser les para-fonctions encore persistantes",

                        ]
                    },
                ],
            },

            traitsRightThirdData: {
                photobefore3: inv1,
                photoafter3: inv2,
                title3: "Chez l'adulte",
                descrip3: "Le Dr Hanras pourra vous proposer selon vos souhaits mais aussi selon le diagnostic ",
                content3: [
                    {
                        title: [
                            "L'orthodontie de l'adulte vise aussi un résultat esthétique et fonctionnel",
                        ],

                        content: [
                            "Soit un traitement multibague (cf ado)",
                            "Soit un traitement par aligneurs transparents. Nb : Il est bon de rappeler que les traitements par aligneurs ne peuvent pas traiter toutes les mal-occlusions et dysmorphoses",
                        ]
                    },
                ],
            },

        },
    ],


    Contact: [

        {
            ContactLeftData: {
                photocline: cline,
                contentcline: "Céline vous accompagne au téléphone et au cabinet d’orthodontie pour toutes les questions en lien avec vos rendez-vous et vos démarches administratives.",
                title: "Nous contacter",
                content: (
                    <div>
                      Le cabinet Orthosmile est situé au 16 rue Benjamin Franklin 78000 à Versailles.
                      <br />
                      Vous pouvez nous joindre par téléphone au <a href="tel:+33139531414">01 39 53 14 14</a>. 
                      <br />
                      Le secrétariat téléphonique est ouvert mardi, mercredi et vendredi de 11h à 19h.
                      <br />
                      Vous pouvez aussi nous joindre par mail en cliquant ici : <a href="mailto:orthosmile78@gmail.com">orthosmile78@gmail.com</a>.
                      <br />
                    </div>
                  ),                  
                buttons: [
                    {
                        text: "Appeler",
                        action: "call", // Vous pouvez définir une action spécifique pour chaque bouton
                    },
                    {
                        text: "Prendre Rendez-vous",
                        action: "book", // Vous pouvez définir une action spécifique pour chaque bouton
                    },
                ],
            },
        }

    ],

};

export default data;
