import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null; // ce composant n'a pas besoin de rendre quoi que ce soit visuellement
}
export default ScrollToTop;