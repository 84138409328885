import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import CallIcon from '@mui/icons-material/Call';
import { SvgIcon } from '@mui/material';
import '../index.css';

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

 

    const toggleMobileMenu = () => {
      setMobileMenuOpen(!isMobileMenuOpen);
    };
  

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };



  const phoneNumber = '0139531414';

  return (
    <div className='header'>
      <div className='flex-header'>
        <div className='left-flex-header'>
          <div className={`burger-menu ${isMobileMenuOpen ? 'open' : ''}`} onClick={toggleMobileMenu}>
            <div className='bar'></div>
            <div className='bar'></div>
            <div className='bar'></div>
          </div>
          {/* ... Reste de vos liens pour le menu desktop ... */}
        </div>

        <div className={`mobile-nav-links ${isMobileMenuOpen ? 'open' : ''}`}>
        <div className='flex-mobile-link'> 
        <NavLink to="/" className={({ isActive }) => isActive ? 'mobile-link active-link' : 'mobile-link'} onClick={toggleMobileMenu}> Accueil</NavLink>
        <NavLink to="/lecabinet" className={({ isActive }) => isActive ? 'mobile-link active-link' : 'mobile-link'} onClick={toggleMobileMenu}>Le cabinet</NavLink>
        <NavLink to="/lestraitements" className={({ isActive }) => isActive ? 'mobile-link active-link' : 'mobile-link'} onClick={toggleMobileMenu}>Les traitements</NavLink>
        <NavLink to="/contact" className={({ isActive }) => isActive ? 'mobile-link active-link' : 'mobile-link'} onClick={toggleMobileMenu}>Contact</NavLink>
        </div>
      </div>

        <div className='right-flex-header'>
          <p className='link-header-right'>Une question ? <br/> Appelez-nous </p>
          <div className='tel'>
          <a className='tel-a' href={`tel:${phoneNumber}`}>
            <SvgIcon padding="8px" component={CallIcon}></SvgIcon>
            </a>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Header;

