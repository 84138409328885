import React from 'react'
import Cards6 from '../simple/Cards/Cards6'
import Info from '../simple/Info'
import InfoCont from '../simple/InfoCont'
import PicInfoCont from '../simple/PicInfoCont'
import data from '../../data'; // Importer les données du fichier data.js


const HomeRight = () => {

  const { photo,  content, linkText, linkHref } = data.Home[1].homeRightFirstData;
  const { cards } = data.Home[1].homeRightSecondData;
  const { titlePhilo ,contentPhilo, linkTextPhilo,linkHrefPhilo } = data.Home[1].homeRightThirdData;

  return (
    <div className='home-right'>
    <PicInfoCont photo={photo} content={content} linkText={linkText} linkHref={linkHref} />
    <Cards6 cards={cards}/> 
    <Info title={titlePhilo} content={contentPhilo} linkText={linkTextPhilo} linkHref={linkHrefPhilo} />
    <InfoCont/>
    </div>
  )
}

export default HomeRight