import React from 'react'
import Card from 'react-bootstrap/Card';
import data from '../../data'; // Importer les données du fichier data.js

const ContactLeft = () => {

  const { title, content, buttons } = data.Contact[0].ContactLeftData;
  
  const handleButtonClick = (action) => {
    if (action === 'book') {
      // Rediriger vers la page Doctolib lorsque le bouton "Prendre Rendez-vous" est cliqué
      window.location.href = 'https://www.doctolib.fr/dentiste/versailles/marie-christine-hanras/booking/motives?telehealth=false&placeId=practice-23885&specialityId=1';
    } else if (action === 'call') {
      // Initier un appel téléphonique lorsque le bouton "Appeler" est cliqué
      window.location.href = 'tel:0139531414';
    }
    // Vous pouvez gérer d'autres actions de bouton ici si nécessaire
  };


  return (
    <div className='left-flex-cab-pad0'>
      <Card className='left-card-contact'>
        <Card.Body className='flex-dr'>
          <Card.Title className='cab-text'>{title}</Card.Title>
          <Card.Text className='curren-black-contact'>
            {content}
          </Card.Text>
          <div className='btn-flex'>
            {buttons.map((button, index) => (
              <button key={index} 
              className='default-btn'
              onClick={() => handleButtonClick(button.action)}>
                {button.text}
              </button>
            ))}
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ContactLeft