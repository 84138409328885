import React from 'react'
import GoogleMap from '../simple/GoogleMap'
import PicInfoCont from '../simple/PicInfoCont'
import data from '../../data'; // 


const { photocline, contentcline } = data.Contact[0].ContactLeftData;

const ContactRight = () => {
  return (
    <div className='contact-right'>
        <PicInfoCont photo={photocline} content={contentcline} />
        <GoogleMap />
        
    </div>
  )
}

export default ContactRight