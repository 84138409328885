import React from 'react'
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';


const PicInfoCont = ({ photo, content, linkText,linkHref }) => {


  return (
    <Card className='piconfocont' >
      <div className="image-overlay"></div>
      <Card.Img className='img-info-cont' variant="top" src={photo} />
      <Card.Body className='flex-dr-home'>
        <Card.Text className='current-m0'>
          {content}
        </Card.Text>
        <Link to={linkHref} className='link-classic'>{linkText}</Link>
      </Card.Body>
    </Card>
  )
}

export default PicInfoCont