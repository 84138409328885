import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';

const InfoCont = () => {
  const texts = [
    {
      mainText: (
      <div>
       " Équipe très sympathique et chaleureuse.
        <br />
        L'assistante très pédagogue avec les enfants, elle sait se montrer patiente. 
        <br />
        Le docteur prend le temps d'expliquer les soins avec plaisir et le sourir en plus.
        <br />
        C'est très agréable ! "
      </div>),
      subText: "Samira Lakehal"
    },

    {
      mainText: (
      <div>
       " Super professionnelle. Aucun reproche ni critique a faire à madame hanras.
        <br />
        Mon fils a des dents magnifiques. 
        <br />
        Toujours disponible.
      </div>),
      subText: "Philippe Sanchez"
    },

    {
      mainText: (
      <div>
       " Je ne peux pas juger le Docteur Hanras comme orthodontiste mais je l ai connue comme ma dentiste et elle était extrêmement compétente.
        <br />
        Je n ai jamais eu mal alors que comme tous les mecs je suis douillet et ses interventions étaient extrêmement pro. 
        <br />
        Le sourire en plus. "
      </div>),
      subText: "Philippe Ewing"
    },

    {
      mainText: (
      <div>
       " Praticien très professionnel aux conseils clairs et rassurants.
        <br />
        L'accueil est bienveillant et tout cela m'a mis en confiance pour démarrer les soins. 
      </div>),
      subText: "Patrick Delattre"
    },

    {
      mainText: (
      <div>
       " Très bon travail "
      </div>),
      subText: "Nelly L"
    },

    


  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Card className='avis'>
      <Card.Body className='flex-dr'>
        <Card.Title style={{ textAlign: 'centered' }} className='title-h2-centered'>Ce qu'en pensent mes patients</Card.Title>
        <Card.Text style={{ textAlign: 'centered' }} className='current-centered'>
          {texts[currentIndex].mainText}
        </Card.Text>
        <Card.Text className="sub-avis">{texts[currentIndex].subText}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default InfoCont;
