import React from 'react'
import data from '../../data';

const HomeText = () => {

  const { title, address, buttons, svgIcon } = data.Home[0].homeLeftData;

  const handleButtonClick = (action) => {
    if (action === 'book') {
      // Rediriger vers la page Doctolib lorsque le bouton "Prendre Rendez-vous" est cliqué
      window.location.href = 'https://www.doctolib.fr/dentiste/versailles/marie-christine-hanras/booking/motives?telehealth=false&placeId=practice-23885&specialityId=1';
    } else if (action === 'call') {
      // Initier un appel téléphonique lorsque le bouton "Appeler" est cliqué
      window.location.href = 'tel:0139531414';
    }
    // Vous pouvez gérer d'autres actions de bouton ici si nécessaire
  };

  return (
    <div className='left-flex'>
      <div className='sub-left-flex'>
        <h1 className='home-text'>{title}</h1>
        <div className='btn-flex'>
          {buttons.map((button, index) => (
            <button key={index} 
            className='default-btn'
            onClick={() => handleButtonClick(button.action)}
            >
              {button.text}
            </button>
          ))}
        </div>
      </div>
      <div className='subflex'>
        <p className="sub">{address}</p>
        <div className="arrow-home" dangerouslySetInnerHTML={{ __html: svgIcon }} />
      </div>
    </div>
  )
}

export default HomeText