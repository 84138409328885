import React from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

const InfoBil = ({ title, content }) => {
    const isComplexContent = Array.isArray(content[0]);
    const [activeKey, setActiveKey] = React.useState("0");

    return (
        <Card className='info-bil'>
            <Card.Title className="title-info-bil">{title}</Card.Title>
            <Card.Body className='flex-dr' style={{ borderRadius: '0px 0px 10px 10px', borderLeft: '1px dashed #000', borderRight: '1px dashed #000', borderBottom: '1px dashed #000' }}>
                {isComplexContent ? (
                    <Accordion defaultActiveKey="0" onSelect={(key) => setActiveKey(key)}>
                        {content[0].map((item, index, arr) => {
                            if (item.title) {
                                return (
                                    <div key={index}>
                                        <Accordion.Item
                                            eventKey={index.toString()}
                                            style={{ borderBottom: index !== arr.length - 2 ? '1px dashed rgb(0, 0, 0)' : undefined }}  // Ne pas ajouter la bordure pour le dernier élément
                                        >
                                            <div className='flex-accordeon'>
                                                <Accordion.Header className='current-m0' style={{ color: item.color }}>
                                                    {item.title}
                                                    <svg className='arrow-accord'
                                                        style={{ transform: activeKey === index.toString() ? "rotate(180deg)" : "rotate(0deg)" }}
                                                        width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.64645 4.35355C3.84171 4.54882 4.15829 4.54882 4.35355 4.35355L7.53553 1.17157C7.7308 0.976311 7.7308 0.659728 7.53553 0.464466C7.34027 0.269204 7.02369 0.269204 6.82843 0.464466L4 3.29289L1.17157 0.464466C0.976311 0.269204 0.659728 0.269204 0.464466 0.464466C0.269204 0.659728 0.269204 0.976311 0.464466 1.17157L3.64645 4.35355ZM3.5 3L3.5 4L4.5 4L4.5 3L3.5 3Z" fill="black" />
                                                    </svg>
                                                </Accordion.Header>

                                            </div>
                                            <Accordion.Body className='current-m0' style={{ color: arr[index + 1]?.color || 'black' }}>
                                                {arr[index + 1]?.text}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </Accordion>
                ) : (
                    <Card.Text className='current'>
                        {content.map((dataItem, index) => (
                            <div className='paragraphe' key={index}>
                                <strong>{dataItem.age}</strong>
                                {dataItem.details.map((paragraph, paragraphIndex) => (
                                    <p key={paragraphIndex} className='current-m0'>{paragraph}</p>
                                ))}
                            </div>
                        ))}
                    </Card.Text>
                )}
            </Card.Body>
        </Card>
    );
}

export default InfoBil;
