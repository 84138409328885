import React from 'react'
import Card from 'react-bootstrap/Card';


const PicCab = ({photo1,photo2,photo3}) =>{
    return (
        <div className='flex-cab-hor'>
            <Card style={{ width: 'auto', border: 'none', marginRight: '2vw' }}>
                <Card.Img className='img-cab'  variant="top" src={photo1} />
            </Card>

            <div className='flex-cab-vert'>
                <Card style={{ width: 'auto', border: 'none' }}>
                    <Card.Img className='img-cab-right' variant="top" src={photo2} />
                </Card>
                <Card style={{ width: 'auto', border: 'none'}}>
                    <Card.Img className='img-cab-right2' variant="top" src={photo3} />
                </Card>

            </div>

        </div>
    )
}

export default PicCab;