import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import HomeLeft from './components/left/HomeLeft';
import HomeRight from './components/right/HomeRight';
import CabinetLeft from './components/left/CabinetLeft';
import CabRight from './components/right/CabRight';
import TraitsLeft from './components/left/TraitsLeft';
import TraitsRight from './components/right/TraitsRight';
import ContactLeft from './components/left/ContactLeft';
import { ScrollContext } from './ScrollContext';
import data from './data';
import ContactRight from './components/right/ContactRight';
import ScrollToTop from "./components/ScrollToTop";

function App() {


  const [activeRoute, setActiveRoute] = useState('/');
  const [arrowDirection, setArrowDirection] = useState('down');


  const beforeAfterRef1 = useRef(null);
  const beforeAfterRef2 = useRef(null);
  const beforeAfterRef3 = useRef(null);

  const { svgIcon } = data.Home[0].homeLeftData;


  useEffect(() => {
    const checkScroll = () => {
      if (window.scrollY > window.innerHeight * 0.2) {
        setArrowDirection('down');
      } else {
        setArrowDirection('up');
      }
    };
    window.addEventListener('scroll', checkScroll);
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, []);


  const handleSetActiveRoute = (route) => {
    setActiveRoute(route);
  };


  const handleScroll = () => {
    if (window.scrollY > 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: window.innerHeight * 0.93,
        behavior: 'smooth',
      });
    }
  };
  
  return (
    <div className="App">
      <Router>
        <Header onSetActiveRoute={handleSetActiveRoute} />
        <ScrollToTop />
        <ScrollContext.Provider value={{ beforeAfterRef1, beforeAfterRef2, beforeAfterRef3 }}>
          <div className="container-app">
            <div className="left-panel">
              <Routes>
                <Route path="/" element={<HomeLeft />} />
                <Route path="/lecabinet" element={<CabinetLeft />} />
                <Route path="/lestraitements" element={<TraitsLeft />} />
                <Route path="/Contact" element={<ContactLeft />} />
              </Routes>
            </div>

            <div className="right-panel">
              <Routes>
                <Route path="/" element={<HomeRight />} />
                <Route path="/lecabinet" element={<CabRight />} />
                <Route path="/lestraitements" element={<TraitsRight />} />
                <Route path="/Contact" element={<ContactRight />} />
              </Routes>
            </div>

            <button className="subflex-all" onClick={handleScroll}>
              <div className={`arrow-all ${arrowDirection === 'up' ? 'arrow-up' : 'arrow-down'}`} dangerouslySetInnerHTML={{ __html: svgIcon }} />
            </button>

          </div>
        </ScrollContext.Provider>
      </Router>
    </div>
  );
}

export default App;
