import React from 'react'
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

const Info = ({title,content,subtitle, subsub, linkText,linkHref}) => {
  const isComplexContent = Array.isArray(content[0]);

  const renderDetails = (details, index) => {
    // Si "details" est un tableau de chaînes, afficher comme paragraphes
    if(typeof details[0] === 'string') {
      return details.map((paragraph, paragraphIndex) => (
        <p key={paragraphIndex} className='current-m0'>{paragraph}</p>
      ));
    }

    // Si "details" est un tableau d'objets avec une propriété "list", afficher comme liste
    if(typeof details[0] === 'object' && details[0].list) {
      return (
        <div key={index}>
        <p className='current-m0'>{subtitle}</p>
          <p className='current-m0'>{details[0].description}</p>
          <ul>
            {details[0].list.map((item, listItemIndex) => (
              <li className='current-m0' key={listItemIndex}>{item}</li>
            ))}
          </ul>
          <p className='current-m0'>{subsub}</p>
        </div>
      );
    }
  };

  return (
    <Card className='info-comp'>
      <Card.Body className='flex-dr'>
        <Card.Title className='title-h2'>{title}</Card.Title>
        {isComplexContent ? (
          <Card.Text className='current'>
              {content.map((item, index) => (
                  <span className='current-m0' key={index}>
                      {item.map((part, partIndex) => (
                          <span key={partIndex} style={{ color: part.color }}>{part.text}</span>
                      ))}
                  </span>
              ))}
          </Card.Text>
        ) : (
          <Card.Text className='current'>
              {content.map((dataItem, index) => (
                  <div className='paragraphe' key={index}>
                      <strong>{dataItem.age}</strong>
                      {renderDetails(dataItem.details, index)}
                  </div>
              ))}
          </Card.Text>
        )}

        <Link to={linkHref} className='link-classic'>{linkText}</Link>
      </Card.Body>
    </Card>
  )
}

export default Info;
