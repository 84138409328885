import React, { useState, useEffect, forwardRef } from 'react';
import Card from 'react-bootstrap/Card';

const BeforeAfter = forwardRef(({ photoBefore, photoAfter,title, content, descrip }, ref) => {

  const windowWidth = window.innerWidth;
  const width = windowWidth <= 768 ? 350 : 580;
  const interval = 3000;
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  // Utilisation de photoBefore et photoAfter comme images du carrousel
  const images = [photoBefore, photoAfter];

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setActiveImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, interval);

    return () => clearInterval(imageInterval);
  }, [interval]);

  return (
    <div className='card-slider' ref={ref}>
      <div className="carousel-container">
        {images.map((imgUrl, index) => (
          <img
            key={index}
            src={imgUrl}
            alt={`carousel-item-${index}`}
            className={`carousel-image ${
              index === activeImageIndex ? 'active' : ''
            }`}
          />
        ))}
      </div>
      <Card className='card-beforeafter'>
        <Card.Body className='flex-dr'>
          <Card.Title className='title-h2'>{title}</Card.Title>
          {content.map((section, index) => (
            <div key={index} className='paragraphe'>
              <div className='traits-fist-sec'>
                {Array.isArray(section.title) ? (
                  section.title.map((titleText, titleIndex) => (
                    <React.Fragment key={titleIndex}>
                      {titleText}
                      {titleIndex < section.title.length - 1 && <br />}
                    </React.Fragment>
                  ))
                ) : (
                  <p className='current-m0'>{section.title}</p>
                )}
              </div>

              <p className='current-m0'>{descrip}</p>

              <ul>
                {section.content.map((text, textIndex) => (
                  <li key={textIndex}>{text}</li>
                ))}
              </ul>
            </div>
          ))}
        </Card.Body>
      </Card>
    </div>
  );
});

export default BeforeAfter;
