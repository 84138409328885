import React from 'react';

const GoogleMap = () => {

    function calculateIframeHeight() {
        return window.innerWidth < 768 ? '65vh' : '410px';
      }


    const iframeSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4662.319063209772!2d2.1317635689620538!3d48.79863874671882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e67dae301b9733%3A0x9c45a06cb92c48e3!2s13%20Rue%20Benjamin%20Franklin%2C%2078000%20Versailles!5e0!3m2!1sfr!2sfr!4v1696321489646!5m2!1sfr!2sfr";
    const address = "16 rue Benjamin Franklin 78000 Versailles"
    return (
        <div className='flex-map'>
            <div className='title-h2-contact'>Ou nous trouver</div>
            <div className="iframe-wrapper"> 
            <div className="iframe-container"
            style={{ width: '101%', height: '410px', maxWidth: '600px', margin: '0 auto' }}>
                <iframe
                    title="Google Map"
                    src={iframeSrc}
                    width="101%"
                    height={calculateIframeHeight()}
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    
                />
            </div>
            </div>
            <div className='subflex-map'> 
            <p className="sub">{address}</p>
            <div className="arrow-contact">
                <svg width="22" height="9" viewBox="0 0 22 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="Vector 11" d="M21.3536 4.85355C21.5488 4.65829 21.5488 4.34171 21.3536 4.14645L18.1716 0.964465C17.9763 0.769203 17.6597 0.769203 17.4645 0.964465C17.2692 1.15973 17.2692 1.47631 17.4645 1.67157L20.2929 4.5L17.4645 7.32843C17.2692 7.52369 17.2692 7.84027 17.4645 8.03553C17.6597 8.2308 17.9763 8.2308 18.1716 8.03553L21.3536 4.85355ZM0.5 5L21 5L21 4L0.5 4L0.5 5Z" fill="black" />
                </svg>
            </div>
        </div>
        </div>
    );
};

export default GoogleMap;
