import React from 'react'
import PicCab from '../simple/PicCab'
import Cards4 from '../simple/Cards/Cards4';
import Info from '../simple/Info'
import data from '../../data'; // Importer les données du fichier data.js
import InfoBil from '../simple/InfoBil';

const CabRight = () => {

  const { photo1,photo2,photo3 } = data.LeCab[1].cabRightFirstData;
  const { cards } = data.LeCab[1].cabRightSecondData;
  const { titlePrise ,contentPrise } = data.LeCab[1].cabRightHalfThirdData;
  const { titleHono ,contentHono, subtitleHono, sub } = data.LeCab[1].cabRightThirdData;
  const { titleBil ,contentBil } = data.LeCab[1].cabRightFourthdData;
  const { titleUrg ,contentUrg,linkTextUrg,linkHrefUrg } = data.LeCab[1].cabRightFifthdData;

  return (
    <div className='cab-right'>
      <PicCab photo1={photo1} photo2={photo2} photo3={photo3} />
      <Cards4 cards={cards} />
      <Info title={titlePrise} content={contentPrise} />
      <Info title={titleHono} content={contentHono} subtitle={subtitleHono} subsub={sub} />
      <InfoBil title={titleBil} content={contentBil} />
      <Info title={titleUrg} content={contentUrg} linkText={linkTextUrg} linkHref={linkHrefUrg}/> 
    </div>
  )
}

export default CabRight;