import React from 'react'
import SingleCards from './SingleCards'

const Cards4 = ({ cards }) => {
  return (
    < div className='flex-apt-home'>
    <div className='flex-apt-homex'> 
    {cards[0] && <SingleCards card={cards[0]} />}
    {cards[1] && <SingleCards card={cards[1]} />}
    </div>
    <div className='flex-apt-homex'> 
    {cards[2] && <SingleCards card={cards[2]} />}
    {cards[3] && <SingleCards card={cards[3]} />}
    </div>
  </div>
  )
}

export default Cards4