import React from 'react'
import Card from 'react-bootstrap/Card';
import data from '../../data'; // Importer les données du fichier data.js

const CabinetLeft = () => {
    const { title, content } = data.LeCab[0].CabinetLeftData;
    return (
        <div className='left-flex-cab-pad0'>
            <Card className='left-cab-card'>
                <Card.Body className='flex-dr'>
                    <Card.Title className='cab-text'>{title}</Card.Title>
                    <div className='current'>
                        {content.map((paragraph, index) => (
                            <p key={index} className='current-black'>{paragraph}</p>
                        ))}
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default CabinetLeft