import React from 'react';
import BeforeAfter from '../simple/BeforeAfter';
import data from '../../data'; // Importer les données du fichier data.js
import { useScroll } from '../../ScrollContext';
import Card from 'react-bootstrap/Card';


const TraitsRight = () => {

    const { photobefore1, photoafter1, photoafterr,photoafterrr,photoafterrrr, title1, descrip1, content1 } = data.Traits[1].traitsRightFirstData;
    const { photobefore2, photoafter2, title2, descrip2, content2 } = data.Traits[1].traitsRightSecondData;
    const { photobefore3, photoafter3, title3, descrip3, content3 } = data.Traits[1].traitsRightThirdData;

    const { beforeAfterRef1, beforeAfterRef2, beforeAfterRef3 } = useScroll();


    return (
        <div className='traits-right'>
            <BeforeAfter 
                ref={beforeAfterRef1}
                photoBefore={photobefore1}
                photoAfter={photoafter1}
                photoafterr={photoafterr}
                title={title1}
                content={content1} 
                descrip={descrip1}
            />

            <BeforeAfter
                ref={beforeAfterRef2}
                photoBefore={photobefore2}
                photoAfter={photoafter2}
                title={title2}
                content={content2}
                descrip={descrip2} 
            />

            <BeforeAfter 
                ref={beforeAfterRef3}
                photoBefore={photobefore3}
                photoAfter={photoafter3}
                title={title3}
                content={content3} 
                descrip={descrip3} 
            />
<Card className='important-card'> 
<Card.Body className='flex-dr'>
<strong>Important</strong>
<Card.Text className='current-m0'>
    Quelque soit l'âge, quelque soit le type de traitement, la réussite du traitement orthodontique dépend aussi du patient :
</Card.Text>
<ul> 
    <li>de sa bonne hygiène bucco-dentaire</li>
    <li>des visites fréquentes chez son dentiste pour soigner les éventuelles caries ou maladies parodontales</li>
    <li>de son respect des rendez-vous</li>
    <li>du respect des consignes de Céline et/ou du Dr Hanras</li>
</ul>
</Card.Body>
</Card>
        </div>
    );
};

export default TraitsRight;
