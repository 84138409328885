import React from 'react'
import Card from 'react-bootstrap/Card';

const SingleCards = ({ card }) => {
  if (!card) {
    return null; // ou affichez un composant de chargement, une erreur, etc.
  }

  return (
    <Card className='cardx4x6' key={card.id}>
      <Card.Body className='single-card-body' >
        <Card.Text className='current-black-card' >
          {card.text}</Card.Text>
          {card.svgIcon && (
        <div style={{ flexShrink: 0}}> {/* Conteneur pour le SVG avec une largeur fixe */}
          {card.svgIcon}
        </div>
      )}
          </Card.Body>
      <Card.Text style={{textAlign:'start', marginBottom:'1vh', paddingLeft:'0.5rem'}} className="sub">{card.sub}</Card.Text>
    </Card>
  )
}

export default SingleCards;
