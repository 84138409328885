import React from 'react'
import SingleCards from './SingleCards'


const Cards6 = ({ cards }) => {
  return (
    <div className='flex-apt-home'>
      <div className='flex-apt-homex'> 
        {cards[0] && <SingleCards card={cards[0]} />}
        {cards[1] && <SingleCards card={cards[1]} />}
        {cards[2] && <SingleCards card={cards[2]} />}
      </div>
      <div className='flex-apt-homex'> 
        {cards[3] && <SingleCards card={cards[3]} />}
        {cards[4] && <SingleCards card={cards[4]} />}
        {cards[5] && <SingleCards card={cards[5]} />}
      </div>
    </div>
  )
}

export default Cards6;





